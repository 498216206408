<template>
  <div>
    <section class="section-watch grid-watch" id="warranty">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <router-link :to="{ name: 'MyWatch', params: { watchId: watchId } }" class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img id="watch-model-logo" :src="model.assets.logo" alt="" />
        </router-link>
        <div @click="showMenu=true" id="show-menu" key="show" class="jbtn jbtn-text show-l" v-bind:class="{'hide-menu': showMenu,}"><i class="fal fa-chevron-down"></i> Show Menu</div>
        <div @click="showMenu=false" id="hide-menu" key="hide" class="jbtn jbtn-text" v-bind:class="{'collapse': !showMenu,}"><i class="fal fa-chevron-up"></i> Hide Menu</div>
        <div class="watch-menu" v-bind:class="{'hide-l': true, 'show-menu': showMenu,}">
          <router-link
            :to="{ name: 'Certificate', params: { id: watchId } }"
            class="jbtn jbtn-watch-nav"
          >
            Info
          </router-link>
          <router-link
            :to="{ name: 'ServicesList', params: { watchId: watchId } }"
            class="jbtn jbtn-watch-nav"
          >
            Servicing
          </router-link>
          <router-link :to="{ name: 'ServiceHistory', params: { id: watchId } }" class="jbtn jbtn-watch-nav"
            >Service History</router-link
          >
          <!-- <router-link :to=" { name: 'PaymentsWatch', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Payments</router-link>
        <router-link :to=" { name: 'Tutorials', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Tutorials</router-link> -->
          <router-link :to="{ name: 'Warranty', params: { id: watchId } }" class="jbtn jbtn-watch-nav active"
            >Warranty</router-link
          >

          <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
            <small>SERIAL</small><br />
            {{ watch.serial }}
          </div>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right">
        <div class="jcard-dark jcard-watch-info">
          <div class="jcard-title">
            <h1>Warranty</h1>
            <p class="p-text-s mt-3">
              DUKE certifies that your watch is guaranteed against any malfunction, equipment failure or
              manufacturing defect for a period of 8 years from the date of its purchase under certain
              conditions.
            </p>
            <p class="p-text-s mt-3"><i class="fal fa-info-circle"></i> 8 years warranty in 3 stages</p>
          </div>
                    <!-- line -->
                    <div class="line"></div>
          <!-- warranty 1 -->
          <div class="warranty-grid">
            <div class="warranty-title-left">
              <p class="p1-platform p-grey">Stage 1</p>
              <h2>3 Years</h2>
              <div class="jbtn jbtn-status jbtn-active">
                {{ $C.STATUS.WARRANTY_STATUS_INDEX[watch.warranties[0].status] }}
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Start Date</p>
                <p class="p1-platform">
                  {{ $moment($C.getDateFromTimestamp(watch.warranties[0].start)).format("MMM YYYY") }}
                </p>
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Expiry Date</p>
                <p class="p1-platform">
                  {{ $moment($C.getDateFromTimestamp(watch.warranties[0].end)).format("MMM YYYY") }}
                </p>
              </div>
            </div>
          </div>
          <!-- line -->
          <div class="line"></div>
          <!-- warranty 2 -->
          <div class="warranty-grid">
            <div class="warranty-title-left">
              <p class="p1-platform p-grey">Stage 2</p>
              <h2>3 Years</h2>
              <div class="jbtn jbtn-status jbtn-inactive">
                {{
                  watch.warranties[1].status
                    ? $C.STATUS.WARRANTY_STATUS_INDEX[watch.warranties[1].status]
                    : "Inactive"
                }}
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Start Date</p>
                <p class="p1-platform">
                  {{
                    watch.warranties[1].status
                      ? $moment($C.getDateFromTimestamp(watch.warranties[1].start)).format("MMM YYYY")
                      : "/"
                  }}
                </p>
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Expiry Date</p>
                <p class="p1-platform">
                  {{
                    watch.warranties[1].status
                      ? $moment($C.getDateFromTimestamp(watch.warranties[1].end)).format("MMM YYYY")
                      : "/"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- additional text -->
          <p class="p-text-s mt-3 text-center">
            <small>
              To activate the 3 years warranty extension, you need to proceed with a movement service before
              the expiry date of stage 1.</small
            >
          </p>
          <!-- line -->
          <div class="line"></div>
          <!-- warranty 3 -->
          <div class="warranty-grid">
            <div class="warranty-title-left">
              <p class="p1-platform p-grey">Stage 3</p>
              <h2>2 Years</h2>
              <div class="jbtn jbtn-status jbtn-inactive">
                {{
                  watch.warranties[2].status
                    ? $C.STATUS.WARRANTY_STATUS_INDEX[watch.warranties[2].status]
                    : "Inactive"
                }}
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Start Date</p>
                <p class="p1-platform">
                  {{
                    watch.warranties[2].status
                      ? $moment($C.getDateFromTimestamp(watch.warranties[2].start)).format("MMM YYYY")
                      : "/"
                  }}
                </p>
              </div>
            </div>
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Expiry Date</p>
                <p class="p1-platform">
                  {{
                    watch.warranties[2].status
                      ? $moment($C.getDateFromTimestamp(watch.warranties[2].end)).format("MMM YYYY")
                      : "/"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- additional text -->
          <p class="p-text-s mt-3 text-center">
            <small>
              To activate the additional 2 years warranty extension, you need to proceed with a movement
              service before the expiry date of stage 2.
            </small>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatch",
  data() {
    return {
      showMenu:false,
      isLoading: false,
      userId: "",
      watchId: "",
      modelId: "",
      model: {
        id: "",
        name: "First Edition",
        assets: {
          front: "",
          logo: ""
        }
      }
    };
  },
  methods: {
    getWatch: function(watchId) {
      db.collection("watches")
        .doc(watchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watches.push(docdata);

            //load watch model
            this.modelId = doc.data().modelId;
            this.getWatchModel();
          });
        })
        .catch(error => {
          console.log("Error getting watch: ", error);
        });
    },
    // LOAD watch model
    getWatchModel: function() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  created() {
    //init
    this.userId = auth.currentUser.uid;

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.watchId = this.$route.params.id;
    }

    //load watch
    var watchRef = db.collection("watches").doc(this.watchId);
    watchRef.onSnapshot(doc => {
      this.$store.commit("setWatch", doc.data());
      //   this.userId = doc.data().userId;

      //load watch model
      this.modelId = doc.data().modelId;
      this.getWatchModel();
    });
    //load user
    var userDocRef = db.collection("users").doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped></style>
